<script setup lang="ts">
import { PropType } from 'vue'
import IconLoading from '@/assets/icons/loading.svg'

const props = defineProps({
  variant: {
    type: String as PropType<
      'primary' | 'secondary' | 'tertiary' | 'text' | 'unstyled'
    >,
    default: 'primary',
  },
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const isUnstyled = computed(() => props.variant === 'unstyled')
</script>

<template>
  <button
    :type="type"
    :class="
      isUnstyled
        ? ''
        : `btn ${variant}-btn relative flex items-center justify-center gap-x-3 rounded py-2 px-4 text-xs font-semibold text-s-900 disabled:text-[#cacbd3] `
    "
    :disabled="disabled || loading"
  >
    <IconLoading
      class="absolute"
      :class="[{ invisible: !loading }, `${variant}-spinner`]"
    />

    <div :class="{ invisible: loading }">
      <slot />
    </div>
  </button>
</template>
